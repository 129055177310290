import {
  GET_FACULTIES_OF_CURRENT_USER_START,
  GET_FACULTIES_OF_CURRENT_USER_SUCCESS,
  GET_FACULTIES_OF_CURRENT_USER_FAILURE,
  POST_FACULTY_START,
  POST_FACULTY_SUCCESS,
  POST_FACULTY_FAILURE,
  GET_FACULTIES_START,
  GET_FACULTIES_SUCCESS,
  GET_FACULTY_SUCCESS,
  GET_FACULTIES_FAILURE,
  ARCHIVE_FACULTY_START,
  ARCHIVE_FACULTY_SUCCESS,
  ARCHIVE_FACULTY_FAILURE,
  UNARCHIVE_FACULTY_START,
  UNARCHIVE_FACULTY_SUCCESS,
  UNARCHIVE_FACULTY_FAILURE,
  GETALL_FACULTY_START,
  GETALL_FACULTY_SUCCESS,
  GETALL_FACULTY_FAILURE,
  POST_BULK_FACULTY_START,
  POST_BULK_FACULTY_SUCCESS,
  POST_BULK_FACULTY_FAILURE,
  PUT_FACULTY_NEW_USER_START,
  PUT_FACULTY_NEW_USER_SUCCESS,
  PUT_FACULTY_NEW_USER_FAIL
} from '../actions'

import { checkStatus } from '../helpers.js'

const getFacultiesOfCurrentUserStart = () => {
  return {
    type: GET_FACULTIES_OF_CURRENT_USER_START,
  }
}

const getFacultiesOfCurrentUserSuccess = (data) => {
  const { my_faculties } = data
  return {
    type: GET_FACULTIES_OF_CURRENT_USER_SUCCESS,
    my_faculties,
  }
}

const getFacultiesOfCurrentUserFailure = () => {
  return {
    type: GET_FACULTIES_OF_CURRENT_USER_FAILURE,
  }
}

const getFacultiesOfCurrentUser = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/faculties`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchFacultiesOfCurrentUser = (data) => {
  return async (dispatch) => {
    dispatch(getFacultiesOfCurrentUserStart())
    try {
      const receivedData = await getFacultiesOfCurrentUser(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getFacultiesOfCurrentUserSuccess(response?.data))
    } catch (err) {
      dispatch(getFacultiesOfCurrentUserFailure())
    }
  }
}

const postFacultyStart = () => {
  return {
    type: POST_FACULTY_START,
  }
}

const postFacultySuccess = () => {
  return {
    type: POST_FACULTY_SUCCESS,
  }
}

const postFacultyFailure = () => {
  return {
    type: POST_FACULTY_FAILURE,
  }
}

const postFacultyApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postFaculty = (data) => {
  return async (dispatch) => {
    dispatch(postFacultyStart())
    try {
      const receivedData = await postFacultyApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(postFacultySuccess())
    } catch (err) {
      dispatch(postFacultyFailure())
    }
  }
}

const getFacultiesStart = () => {
  return {
    type: GET_FACULTIES_START,
  }
}

const getFacultiesSuccess = (data) => {
  const { faculties } = data
  return {
    type: GET_FACULTIES_SUCCESS,
    faculties,
  }
}

const getFacultyByIDSuccess = (data) => {
  const { faculty } = data
  return {
    type: GET_FACULTY_SUCCESS,
    faculty,
  }
}

const getFacultiesFailure = () => {
  return {
    type: GET_FACULTIES_FAILURE,
  }
}

const getFacultiesApiCall = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const getFaculties = (data) => {
  return async (dispatch) => {
    dispatch(getFacultiesStart())
    try {
      const receivedData = await getFacultiesApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getFacultiesSuccess(response?.data))
    } catch (err) {
      dispatch(getFacultiesFailure())
    }
  }
}

const getFacultiesApiCallById = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const getFacultiesById = (data) => {
  return async (dispatch) => {
    dispatch(getFacultiesStart())
    try {
      const receivedData = await getFacultiesApiCallById(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getFacultyByIDSuccess(response?.data))
    } catch (err) {
      dispatch(getFacultiesFailure())
    }
  }
}

//edit faculty
const editDataForFaculty = ({ jwt, facultyId, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/${facultyId}`
  const options = {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editFaculty = ({ jwt, facultyId, payload }) => {
  return async (dispatch) => {
    dispatch(getFacultiesStart())
    try {
      const receivedData = await editDataForFaculty({ jwt, facultyId, payload })
      const response = await receivedData.json()
      let student = response.data.student
      dispatch(getFacultyByIDSuccess(student))
    } catch (err) {
      dispatch(getFacultiesFailure())
    }
  }
}

const archiveFacultyStart = () => {
  return {
    type: ARCHIVE_FACULTY_START,
  }
}

const archiveFacultySuccess = (student) => {
  return {
    type: ARCHIVE_FACULTY_SUCCESS,
  }
}
const archiveFacultyFailure = () => {
  return {
    type: ARCHIVE_FACULTY_FAILURE,
  }
}

const archiveFacultyApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/${payload.faculty_id}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const archiveFaculty = (data) => {
  return async (dispatch) => {
    dispatch(archiveFacultyStart())
    try {
      const receivedData = await archiveFacultyApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(archiveFacultySuccess())
    } catch (err) {
      dispatch(archiveFacultyFailure())
    }
  }
}

const unarchiveFacultyStart = () => {
  return {
    type: UNARCHIVE_FACULTY_START,
  }
}

const unarchiveFacultySuccess = (isfaculty) => {
  return {
    type: UNARCHIVE_FACULTY_SUCCESS,
    isfaculty,
  }
}
const unarchiveFacultyFailure = () => {
  return {
    type: UNARCHIVE_FACULTY_FAILURE,
  }
}

const unarchiveFacultyApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/dearc/faculty/${payload.faculty_id}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const unarchiveFaculty = (data) => {
  return async (dispatch) => {
    dispatch(unarchiveFacultyStart())
    try {
      const receivedData = await unarchiveFacultyApiCall(data)
      const response = await receivedData.json()
      let faculty = response.data.faculty
      dispatch(unarchiveFacultySuccess(faculty))
    } catch (err) {
      dispatch(unarchiveFacultyFailure())
    }
  }
}

const fetchFacultiesStart = () => {
  return {
    type: GETALL_FACULTY_START,
  }
}

const fetchFacultiesSuccess = (data) => {
  const { faculties } = data
  return {
    type: GETALL_FACULTY_SUCCESS,
    faculties,
  }
}

const fetchFacultiesFailure = () => {
  return {
    type: GETALL_FACULTY_FAILURE,
  }
}

const fetchFacultiesApiCall = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/complete-list`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchFaculties = (data) => {
  return async (dispatch) => {
    dispatch(fetchFacultiesStart())
    try {
      const receivedData = await fetchFacultiesApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(fetchFacultiesSuccess(response?.data))
    } catch (err) {
      dispatch(fetchFacultiesFailure())
    }
  }
}


//Add bulkStudent
const postBulkFacultyStart = () => ({
  type: POST_BULK_FACULTY_START,
})

const postBulkFacultyFail = (errMsg) => ({
  type: POST_BULK_FACULTY_FAILURE,
  errMsg,
})

const postBulkFacultySuccess = (data) => ({
  type: POST_BULK_FACULTY_SUCCESS,
  data,
})

export const startBulkAddFaculty = (data) => {
  return async (dispatch) => {
    dispatch(postBulkFacultyStart())
    try {
      const receivedData = await bulkAddFaculty(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postBulkFacultySuccess(response.data))
    } catch (err) {
      dispatch(postBulkFacultyFail())
    }
  }
}

const bulkAddFaculty = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/csv`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

//put new Faculty user
const putFacultyUserStart = () => ({
  type: PUT_FACULTY_NEW_USER_START,
})

const putFacultyUserFail = (errMsg) => ({
  type: PUT_FACULTY_NEW_USER_FAIL,
  errMsg,
})

const putFacultyUserSuccess = (data) => ({
  type: PUT_FACULTY_NEW_USER_SUCCESS,
  data,
})

export const startPutFacultyUser = (data) => {
  return async (dispatch) => {
    dispatch(putFacultyUserStart())
    try {
      const receivedData = await putFacultyUser(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(putFacultyUserSuccess(response.data))
    } catch (err) {
      dispatch(putFacultyUserFail())
    }
  }
}

const putFacultyUser = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/new-user`
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}