import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

//helpers
import { usePrevious } from '../../../../helpers'

//assets
import Loader from '../../../../assets/molecules/loader'
import Select from '../../../../assets/molecules/select'
import Popup from '../../../../assets/layout/popupConfirm'
import { AlertSuccess, AlertError } from '../../../../assets/molecules/alerts'
import PopupStudent from '../../../../assets/layout/popupConfirm'
import Button from '../../../../assets/molecules/button'

//action
import {
  fetchBatches,
  assignStudentBatch,
} from '../../../../../redux/action/batches'
import { setBatch } from '../../../../../redux/action/ui'
import { postStudentReset } from '../../../../../redux/action/account'
import {
  deleteStudent,
  unarchiveStudent,
  startAddStudent,
  startPutStudentUser,
} from '../../../../../redux/action/students'

//reducers
import {
  getJwt,
  getStudentResetIsLoading,
  getStudentResetError,
  getStudentResetSuccess,
} from '../../../../../redux/reducer/account'
import {
  getRemoveIsLoading,
  getRemoveIsError,
  getUnarchiveIsLoading,
  getUnarchiveIsError,
  getIsLoading as isLoadingAddStudent,
  getIsError,
  getAddedStudent,
  getResetStudentLoading
} from '../../../../../redux/reducer/students'
import {
  getBatches,
  getIsLoading,
  getBatchesById,
  getIsLoadingAssignStudentBatch,
  getIsError as getIsErrorBatch,
} from '../../../../../redux/reducer/batches'
import { getBatch } from '../../../../../redux/reducer/ui'
import { getAcademicYear } from '../../../../../redux/reducer/account'

const StudentList = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [popClassName, setPopClassName] = useState('hidePop')
  const [popRemoveStudClassName, setPopRemoveStudClassName] =
    useState('hidePop')
  const [popUnArchiveStudClassName, setPopUnArchiveStudClassName] =
    useState('hidePop')
  const [selectedStudent, setStudent] = useState({ id: '', name: '' })
  const [studentAddPopClassName, setStudentAddPopClassName] =
    useState('hidePop')
  const [formData, setFormData] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [date, setDate] = useState('')
  const [addStudent, setAddStudent] = useState('')
  const [resetStudents, setResetStudents] = useState({})
  const [selectAll, setSelectAll] = useState(true)

  const jwt = useSelector(getJwt)
  const batches = useSelector(getBatches)
  const isLoading = useSelector(getIsLoading)
  const batchSelected = useSelector(getBatch)
  const batchesById = useSelector(getBatchesById)
  const studentResetIsLoading = useSelector(getStudentResetIsLoading)
  const studentResetError = useSelector(getStudentResetError)
  const studentResetSuccess = useSelector(getStudentResetSuccess)
  const studentRemoveIsLoading = useSelector(getRemoveIsLoading)
  const studentRemoveError = useSelector(getRemoveIsError)
  const studentUnarchiveIsLoading = useSelector(getUnarchiveIsLoading)
  const studentUnarchiveError = useSelector(getUnarchiveIsError)
  const studentUnarchiveIsLoadingPrev = usePrevious(studentUnarchiveIsLoading)
  const studentRemoveIsLoadingPrev = usePrevious(studentRemoveIsLoading)
  const studentResetIsLoadingPrev = usePrevious(studentResetIsLoading)
  const studentAddIsLoading = useSelector(isLoadingAddStudent)
  const studentAddIsLoadingPrev = usePrevious(studentAddIsLoading)
  const isErrorBatch = useSelector(getIsErrorBatch)
  const isLoadingAssignStudentBatch = useSelector(
    getIsLoadingAssignStudentBatch
  )
  const isLoadingAssignStudentBatchPrev = usePrevious(
    isLoadingAssignStudentBatch
  )
  const studentAddError = useSelector(getIsError)
  const academicYear = useSelector(getAcademicYear)
  const addedStudent = useSelector(getAddedStudent)
  const resetLoading = useSelector(getResetStudentLoading)
  const resetLoadingPrev = usePrevious(resetLoading)

  useEffect(() => {
    dispatch(fetchBatches({ jwt }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (batchSelected) {
      let studentIds = batchesById[batchSelected].students && batchesById[batchSelected].students.length > 0
        ? batchesById[batchSelected].students?.map(stu => stu.id)
        : []
        if (studentIds && studentIds.length > 0) {
          let resetObj = {}
          studentIds.forEach(id => {
            resetObj[id] = true
          })
          setResetStudents(resetObj)
        }
    }
  }, [batchSelected])

  useEffect(() => {
    if (formData.batch) {
      if (
        studentAddIsLoadingPrev !== undefined &&
        studentAddIsLoadingPrev !== studentAddIsLoading &&
        !studentAddIsLoading &&
        studentAddError
      ) {
        setAddStudent('error')
      } else if (
        studentAddIsLoadingPrev !== undefined &&
        studentAddIsLoadingPrev !== studentAddIsLoading &&
        !studentAddIsLoading &&
        !studentAddError
      ) {
        const data = {
          student_ids: [addedStudent?.student?._id],
        }
        dispatch(
          assignStudentBatch({ jwt, batchId: formData.batch, payload: data })
        )
      }
    }
  }, [studentAddIsLoading, studentAddError, studentAddIsLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isLoadingAssignStudentBatchPrev !== undefined &&
      isLoadingAssignStudentBatchPrev !== isLoadingAssignStudentBatch &&
      !isLoadingAssignStudentBatch &&
      isErrorBatch
    ) {
      setAddStudent('error')
    } else if (
      isLoadingAssignStudentBatchPrev !== undefined &&
      isLoadingAssignStudentBatchPrev !== isLoadingAssignStudentBatch &&
      !isLoadingAssignStudentBatch &&
      !isErrorBatch
    ) {
      setAddStudent('added')
      setFormData({})
      setDate('')
      setErrorMessage('')
      setStudentAddPopClassName('hidePop')
    }
  }, [
    isLoadingAssignStudentBatch,
    isErrorBatch,
    isLoadingAssignStudentBatchPrev,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !studentUnarchiveIsLoading &&
      studentUnarchiveIsLoadingPrev !== undefined &&
      studentUnarchiveIsLoading !== studentUnarchiveIsLoadingPrev &&
      !studentUnarchiveError
    ) {
      dispatch(fetchBatches({ jwt }))
      setPopUnArchiveStudClassName('hidePop')
    }
  }, [
    studentUnarchiveError,
    studentUnarchiveIsLoading,
    studentUnarchiveIsLoadingPrev,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !studentRemoveIsLoading &&
      studentRemoveIsLoadingPrev !== undefined &&
      studentRemoveIsLoading !== studentRemoveIsLoadingPrev &&
      !studentRemoveError
    ) {
      dispatch(fetchBatches({ jwt }))
      setPopRemoveStudClassName('hidePop')
    }
  }, [studentRemoveError, studentRemoveIsLoading, studentRemoveIsLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeBatch = (e) => {
    e.preventDefault()
    // setSelectedBatch(e.target.value)
    dispatch(setBatch(e.target.value))
  }

  const closePopup = () => {
    setPopClassName('hidePop')
  }
  const closeRemoveStudPopup = () => {
    setPopRemoveStudClassName('hidePop')
  }
  const closeUnArchiveStudPopup = () => {
    setPopUnArchiveStudClassName('hidePop')
  }

  const openPopup = (selectedStudent) => {
    setStudent(selectedStudent)
    setPopClassName('showPop')
  }
  const openRemovePopup = (selectedStudent) => {
    setStudent(selectedStudent)
    setPopRemoveStudClassName('showPop')
  }
  const openUnArchivePopup = (selectedStudent) => {
    setStudent(selectedStudent)
    setPopUnArchiveStudClassName('showPop')
  }

  const onReset = () => {
    dispatch(
      postStudentReset({
        payload: {
          student_id: selectedStudent?.id,
        },
        jwt,
      })
    )
    setPopClassName('hidePop')
  }

  const onClickRemove = () => {
    dispatch(
      deleteStudent({
        payload: {
          id: [selectedStudent?.id],
        },
        jwt,
      })
    )
  }
  const onClickUnArchive = () => {
    dispatch(
      unarchiveStudent({
        payload: {
          student_id: selectedStudent?.id,
        },
        jwt,
      })
    )
    setPopUnArchiveStudClassName('hidePop')
  }
  const clickStudent = (stu) => {
    history.push(`/edit/student/${stu.id}`)
  }

  const showAddStudentPopup = () => {
    setStudentAddPopClassName('showPop')
    setFormData({})
  }
  const closeAddStudentPopup = () => {
    setStudentAddPopClassName('hidePop')
    setErrorMessage('')
    setFormData({})
  }

  const onChangeField = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onChangeDate = (date) => {
    setDate(date)
  }

  const onSelectChange = () => {
    setSelectAll(!selectAll)
    let studentState = { ...resetStudents }
    Object.keys(studentState).forEach(studentId => {
      studentState[studentId] = !selectAll
    })
    setResetStudents(studentState)
  }

  const onResetStudentSelect = (e) => {
    setResetStudents({...resetStudents, [e.target.value]: !resetStudents[e.target.value]})
    
    let studentState = { ...resetStudents }
    studentState[e.target.value] = !studentState[e.target.value]

    const checked = Object.keys(studentState).filter(id => studentState[id])

    if (checked.length === Object.keys(resetStudents).length)
      setSelectAll(true)
    else setSelectAll(false)
  }

  const onSubmitAddStudent = () => {
    if (
      date &&
      date !== '' &&
      formData.name &&
      formData.name !== '' &&
      formData.mobile &&
      formData.mobile !== '' &&
      formData.prn &&
      formData.prn !== '' &&
      formData.gender &&
      formData.gender !== '' &&
      formData.mother &&
      formData.mother !== '' &&
      formData.father &&
      formData.father !== '' &&
      formData.upload &&
      formData.upload !== '' &&
      formData.batch &&
      formData.batch !== ''
    ) {
      const payload = {
        name: formData.name,
        admission_year: academicYear,
        gender: formData.gender,
        email: [formData.email],
        mobile: [formData.mobile],
        prn: formData.prn,
        mother_name: formData.mother,
        father_name: formData.father,
        guardian_name: formData.guardian,
        date_of_birth: moment(date).format('YYYY/MM/DD'),
        uploadAssessmentNotAllowed: formData.upload == 'yes' ? true : false,
      }
      if (!formData.email) {
        delete payload.email
      }
      const data = { jwt, payload }
      dispatch(startAddStudent(data))
    } else {
      setErrorMessage('Please fill all mandatory fields')
    }
  }

  const handleResetStudentsSubmit = () => {
    let student_ids = Object.keys(resetStudents).filter(item => resetStudents[item])
    const payload = {
      student_ids
    }
    dispatch(startPutStudentUser({ jwt, payload }))
  }

  return (
    <div className="body__container">
      {studentResetIsLoadingPrev !== undefined &&
        studentResetIsLoadingPrev !== studentResetIsLoading &&
        !studentResetIsLoading &&
        studentResetSuccess && (
          <AlertSuccess>
            You Have successfully Reset {selectedStudent.name} password!!!
          </AlertSuccess>
        )}
      {studentResetIsLoadingPrev !== undefined &&
        studentResetIsLoadingPrev !== studentResetIsLoading &&
        !studentResetIsLoading &&
        studentResetError && (
          <AlertError>
            OOps Something went Wrong while we tried to Reset{' '}
            {selectedStudent.name} password!!! Try Again
          </AlertError>
        )}
      {!resetLoading && resetLoadingPrev !== undefined && resetLoading !== resetLoadingPrev && !studentAddError &&
        <AlertSuccess>Password Reset successfully</AlertSuccess>
      }

      {!resetLoading && resetLoadingPrev !== undefined && resetLoading !== resetLoadingPrev && studentAddError &&
        <AlertError>Password Reset successfully</AlertError>
      }
      <section>
        <div className="row">
          <div className="col-md-6 padding--0">
            <h2>List of Students</h2>
          </div>
          <div className="col-md-6 padding--0">
            <div className="button__wrapper">
              <button
                onClick={() => showAddStudentPopup()}
                className="button--blue"
              >
                + Add Student
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Student List</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-3 padding--0">
            <div className="tabs__wrapper">
              {isLoading ? (
                <Loader />
              ) : (
                <Select
                  className="float-right"
                  onChange={(e) => onChangeBatch(e)}
                >
                  <option value="">Select a Batch</option>
                  {batches?.map((batch, index) => {
                    return (
                      <option
                        value={batch._id}
                        key={index}
                        selected={batch._id === batchSelected}
                      >
                        {batch.title}
                      </option>
                    )
                  })}
                </Select>
              )}
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="table-responsive">
          <table className={'table'}>
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Name</th>
                <th>Reset Password</th>
                <th>Archive</th>
                <th>UnArchive</th>
                <th>Bulk Reset</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5}></td>
                <td>
                  <input
                    type="checkbox"
                    className="checkbox__wrapper"
                    onClick={onSelectChange}
                    checked={selectAll}
                  />
                </td>
              </tr>
              {batchesById &&
                batchesById[batchSelected]?.students?.map((stu, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="pointer">
                        <span onClick={() => clickStudent(stu)}>
                          {stu.name}
                        </span>
                      </td>
                      <td>
                        <button onClick={() => openPopup(stu)}>Reset</button>
                      </td>
                      <td>
                        <button onClick={() => openRemovePopup(stu)}>
                          Archive
                        </button>
                      </td>
                      <td>
                        <button onClick={() => openUnArchivePopup(stu)}>
                          Unarchive
                        </button>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox__wrapper"
                          value={stu.id}
                          onClick={e => onResetStudentSelect(e)}
                          checked={resetStudents[stu.id]}
                        />
                      </td>
                    </tr>
                  )
                })}
              <tr>
                <td colSpan={5}></td>
                <td>
                  {resetLoading ? <Loader /> :
                    <Button
                      className="button--tiny"
                      color="blue"
                      text="Reset"
                      click={(e) => handleResetStudentsSubmit(e)}
                    />
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div className="clear"></div>
      {addStudent == 'error' ? (
        <AlertError>
          OOps Something went Wrong To Add Student!!! Try Again
        </AlertError>
      ) : null}
      {addStudent == 'added' ? (
        <AlertSuccess>You Have successfully Added Student!!!</AlertSuccess>
      ) : null}
      <Popup
        className={popClassName}
        buttonText="Reset"
        popupHead={'Reset Password for ' + selectedStudent?.name}
        close={() => closePopup()}
        onClick={() => onReset()}
      >
        <h2>Are you 100% sure it is the correct student?</h2>
        <p>Are you sure you want to reset this student's password !!!</p>
      </Popup>
      <Popup
        className={popRemoveStudClassName}
        buttonText="Archive"
        popupHead={'Archive "' + selectedStudent?.name + '" from the list'}
        close={() => closeRemoveStudPopup()}
        onClick={() => onClickRemove()}
      >
        <h2>Are you 100% sure it is the correct student?</h2>
        <p>Are you sure want to remove this student?</p>
      </Popup>
      <Popup
        className={popUnArchiveStudClassName}
        buttonText="UnArchive"
        popupHead={'UnArchive "' + selectedStudent?.name + '" to the list'}
        close={() => closeUnArchiveStudPopup()}
        onClick={() => onClickUnArchive()}
      >
        <h2>Are you 100% sure it is the correct student?</h2>
        <p>Are you sure want to unarchive this student?</p>
      </Popup>
      <PopupStudent
        className={'studentAddPop ' + studentAddPopClassName}
        close={() => {
          closeAddStudentPopup()
        }}
        onClick={(e) => onSubmitAddStudent(e)}
        buttonText="Submit"
        popupHead="Add Student"
      >
        <div className="form-row">
          <div className="form-group col">
            <input
              name="name"
              value={formData.name ? formData.name : ''}
              type="text"
              placeholder="Name*"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <select
              name="gender"
              onChange={onChangeField}
              value={formData.gender ? formData.gender : ''}
            >
              <option>Gender*</option>
              <option value="male">male</option>
              <option value="female">female</option>
            </select>
          </div>
          <div className="form-group col">
            <input
              name="email"
              value={formData.email ? formData.email : ''}
              type="email"
              placeholder="Email"
              onChange={onChangeField}
            />
          </div>
          <div className="clear"></div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <input
              name="mobile"
              value={formData.mobile ? formData.mobile : ''}
              placeholder="Mobile Number*"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <input
              name="prn"
              value={formData.prn ? formData.prn : ''}
              type="text"
              placeholder="PRN*"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <input
              className="disable__fields"
              name="admissionYear"
              value={academicYear}
              type="text"
              placeholder="Admission Year*"
              disabled
              // onChange={onChangeField}
            />
          </div>
          <div className="clear"></div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <input
              name="father"
              value={formData.father ? formData.father : ''}
              type="text"
              placeholder="Father Name*"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <input
              name="mother"
              value={formData.mother ? formData.mother : ''}
              type="text"
              placeholder="Mother Name*"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <input
              name="guardian"
              value={formData.guardian ? formData.guardian : ''}
              type="text"
              placeholder="Guardian Name"
              onChange={onChangeField}
            />
          </div>
          <div className="clear"></div>
        </div>
        <div className="form-row">
          <div className="form-group dob_addStudent col">
            <DatePicker
              selected={date}
              onChange={(date) => onChangeDate(date)}
              placeholderText="Date of Birth*"
              dateFormat="MMMM d, yyyy"
            />
          </div>
          <div className="form-group col">
            <select
              name="upload"
              onChange={onChangeField}
              value={formData.upload ? formData.upload : ''}
            >
              <option>Allow to upload assessment*</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="form-group col">
            <select
              name="batch"
              onChange={onChangeField}
              value={formData.batch ? formData.batch : ''}
            >
              <option value="">Select a Batch*</option>
              {batches?.map((batch, index) => {
                return (
                  <option
                    value={batch._id}
                    key={index}
                    selected={formData.batch === batch?._id}
                  >
                    {batch.title}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="clear"></div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            {/* <select
              name="upload"
              onChange={onChangeField}
              value={formData.upload}
            >
              <option>Allow to upload assessment</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select> */}

            {/* <div className="assessment__form">
              <label>Allow to upload assessment</label>
            <ul className="radio__wrapper">
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name="upload"
                        id="uploadYes"
                        value="yes"
                        onChange={onChangeField}
                        // {...radioCorrectProps}
                      />
                      <label
                        htmlFor="uploadYes"
                        className="radio--label"
                      >
                        Yes
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name="upload"
                        id="uploadNo"
                        value="no"
                        onChange={onChangeField}
                        // {...radioWrongProps}
                      />
                      <label
                        htmlFor="uploadNo"
                        className="radio--label"
                      >
                        No
                      </label>
                    </li>
                  </ul>
                  </div> */}
          </div>
          <div className="clear"></div>
        </div>

        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
      </PopupStudent>
    </div>
  )
}

export default StudentList
