import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

//helpers
import { usePrevious } from '../../helpers'

//assests
import Loader from '../../assets/molecules/loader'
import Popup from '../../assets/layout/popupConfirm'
import Button from '../../assets/molecules/button'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

//action
import {
  getFaculties,
  fetchFaculties,
  archiveFaculty,
  unarchiveFaculty,
} from '../../../redux/action/faculty'
import { postTeacherReset } from '../../../redux/action/account'
import { startPutFacultyUser } from '../../../redux/action/faculty'

//reducers
import {
  getJwt,
  getTeacherResetIsLoading,
  getTeacherResetError,
  getTeacherResetSuccess,
} from '../../../redux/reducer/account'
import {
  getIsFacultiesLoading,
  getAllFaculties,
  fetchFalculty,
  fetchIsLoading,
  getArchiveFalculty,
  getArchiveIsLoading,
  getArchiveIsError,
  getUnArchiveFalculty,
  getUnArchiveIsLoading,
  getUnArchiveIsError,
  getIsError as getFacultyError,
  getResetLaoding as getResetFacultyLoading
} from '../../../redux/reducer/faculty'

const TeacherList = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [popClassName, setPopClassName] = useState('hidePop')
  const [popRemoveStudClassName, setPopRemoveStudClassName] = useState(
    'hidePop'
  )
  const [popUnArchiveStudClassName, setPopUnArchiveStudClassName] = useState(
    'hidePop'
  )
  const [selectedTeacher, setTeacher] = useState({ id: '', name: '' })
  const [resetFaculty, setResetFaculty] = useState({})
  const [selectAll, setSelectAll] = useState(true)

  const jwt = useSelector(getJwt)
  const isFacultiesLoading = useSelector(getIsFacultiesLoading)
  const isFacultiesLoadingPrev = usePrevious(isFacultiesLoading)
  const allFaculties = useSelector(getAllFaculties)

  const teacherResetIsLoading = useSelector(getTeacherResetIsLoading)
  const teacherResetError = useSelector(getTeacherResetError)
  const teacherResetSuccess = useSelector(getTeacherResetSuccess)
  const teacherResetIsLoadingPrev = usePrevious(teacherResetIsLoading)

  const facultyRemoveIsLoading = useSelector(getArchiveIsLoading)
  const facultyRemoveError = useSelector(getArchiveIsError)
  const facultyRemoveSuccess = useSelector(getArchiveFalculty)
  const facultyUnarchiveIsLoading = useSelector(getUnArchiveIsLoading)
  const facultyUnarchiveError = useSelector(getUnArchiveIsError)
  const facultyUnarchiveStudents = useSelector(getUnArchiveFalculty)
  const facultyUnarchiveIsLoadingPrev = usePrevious(facultyUnarchiveIsLoading)
  const facultyRemoveIsLoadingPrev = usePrevious(facultyRemoveIsLoading)
  const resetLoading = useSelector(getResetFacultyLoading)
  const resetLoadingPrev = usePrevious(resetLoading)
  const error = useSelector(getFacultyError)

  const isFetchFalculty = useSelector(fetchIsLoading)
  const isFetchFalcultyPrev = usePrevious(isFetchFalculty)
  const fetchFalcultyall = useSelector(fetchFalculty)

  useEffect(() => {
    dispatch(fetchFaculties({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isFetchFalculty && isFetchFalcultyPrev !== undefined) {
      if (fetchFalcultyall && fetchFalcultyall.length > 0) {
        let resetObj = {}
        fetchFalcultyall.forEach(item => {
          resetObj[item?._id] = true
        })
        setResetFaculty(resetObj)
      }
    }
  }, [isFetchFalculty, isFetchFalcultyPrev])

  const closePopup = () => {
    setPopClassName('hidePop')
  }
  const closeRemoveStudPopup = () => {
    setPopRemoveStudClassName('hidePop')
  }
  const closeUnArchiveStudPopup = () => {
    setPopUnArchiveStudClassName('hidePop')
  }
  const openPopup = (selectedTeacher) => {
    setTeacher(selectedTeacher)
    setPopClassName('showPop')
  }
  const openRemovePopup = (selectedTeacher) => {
    setTeacher(selectedTeacher)
    setPopRemoveStudClassName('showPop')
  }
  const openUnArchivePopup = (selectedTeacher) => {
    setTeacher(selectedTeacher)
    setPopUnArchiveStudClassName('showPop')
  }

  const onReset = () => {
    dispatch(
      postTeacherReset({
        payload: {
          faculty_id: selectedTeacher?._id,
        },
        jwt,
      })
    )
    setPopClassName('hidePop')
  }
  const onClickRemove = () => {
    dispatch(
      archiveFaculty({
        payload: {
          faculty_id: selectedTeacher?._id,
        },
        jwt,
      }),
      [dispatch, jwt]
    )
    setPopRemoveStudClassName('hidePop')
  }
  const onClickUnArchive = () => {
    dispatch(
      unarchiveFaculty({
        payload: {
          faculty_id: selectedTeacher?._id,
        },
        jwt,
      })
    )
    setPopUnArchiveStudClassName('hidePop')
  }
  const clickTeacher = (stu) => {
    history.push(`/edit/faculty/${stu._id}`)
  }

  const onSelectChange = () => {
    setSelectAll(!selectAll)
    let facultyState = { ...resetFaculty }
    Object.keys(facultyState).forEach(id => {
      facultyState[id] = !selectAll
    })
    setResetFaculty(facultyState)
  }

  const onResetFacultySelect = (e) => {
    setResetFaculty({ ...resetFaculty, [e.target.value]: !resetFaculty[e.target.value] })

    let facultyState = { ...resetFaculty }
    facultyState[e.target.value] = !facultyState[e.target.value]

    const checked = Object.keys(facultyState).filter(id => facultyState[id])

    if (checked.length === Object.keys(resetFaculty).length)
      setSelectAll(true)
    else setSelectAll(false)
  }

  const handleResetFacultySubmit = () => {
    let faculty_ids = Object.keys(resetFaculty).filter(item => resetFaculty[item])
    const payload = {
      faculty_ids
    }
    dispatch(startPutFacultyUser({ jwt, payload }))
  }

  return isFetchFalculty ? (
    <Loader />
  ) : (
    <div className="body__container">
      {teacherResetIsLoadingPrev !== undefined &&
        teacherResetIsLoadingPrev !== teacherResetIsLoading &&
        !teacherResetIsLoading &&
        teacherResetSuccess && (
          <AlertSuccess>
            You Have successfully Reset {selectedTeacher.name} password!!!
          </AlertSuccess>
        )}
      {teacherResetIsLoadingPrev !== undefined &&
        teacherResetIsLoadingPrev !== teacherResetIsLoading &&
        !teacherResetIsLoading &&
        teacherResetError && (
          <AlertError>
            OOps Something went Wrong while we tried to Reset{' '}
            {selectedTeacher.name} password!!! Try Again
          </AlertError>
        )}

        {!resetLoading && resetLoadingPrev !== undefined && resetLoading !== resetLoadingPrev && !error &&
          <AlertSuccess>Password Reset successfully</AlertSuccess>
        }

        {!resetLoading && resetLoadingPrev !== undefined && resetLoading !== resetLoadingPrev && error &&
          <AlertError>Password Reset successfully</AlertError>
        }
      <section>
        <h2>List of Faculties</h2>
        <div className="row">
          <div className="col-md-9 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Faculty List</li>
              </ol>
            </nav>
          </div>
          <div className="clear"></div>
        </div>
        <div className="table-responsive">
          <table className={'table'}>
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Name</th>
                <th>Reset Password</th>
                <th>Archive</th>
                <th>UnArchive</th>
                <th>Bulk Reset</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5}></td>
                <td>
                  <input
                    type="checkbox"
                    className="checkbox__wrapper"
                    onClick={onSelectChange}
                    checked={selectAll}
                  />
                </td>
              </tr>
              {fetchFalcultyall &&
                fetchFalcultyall?.length > 0 &&
                fetchFalcultyall
                  ?.sort(
                    (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
                  )
                  ?.map((faculties, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="pointer">
                          <span onClick={() => clickTeacher(faculties)}>
                            {faculties.name}
                          </span>
                        </td>
                        <td>
                          <button onClick={() => openPopup(faculties)}>
                            Reset
                          </button>
                        </td>
                        <td>
                          <button onClick={() => openRemovePopup(faculties)}>
                            Archive
                          </button>
                        </td>
                        <td>
                          <button onClick={() => openUnArchivePopup(faculties)}>
                            Unarchive
                          </button>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox__wrapper"
                            value={faculties._id}
                            onClick={e => onResetFacultySelect(e)}
                            checked={resetFaculty[faculties._id]}
                          />
                        </td>
                      </tr>
                    )
                  })}
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    {resetLoading ? <Loader /> :
                      <Button
                        className="button--tiny"
                        color="blue"
                        text="Reset"
                        click={(e) => handleResetFacultySubmit(e)}
                      />
                    }
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div className="clear"></div>
      <Popup
        className={popClassName}
        buttonText="Reset"
        popupHead={'Reset Password for ' + selectedTeacher?.name}
        close={() => closePopup()}
        onClick={() => onReset()}
      >
        <h2>Are you 100% sure it is the correct Faculty?</h2>
        <p>Are you sure you want to reset this Faculty's password !!!</p>
      </Popup>
      <Popup
        className={popRemoveStudClassName}
        buttonText="Archive"
        popupHead={'Archive "' + selectedTeacher?.name + '" from the list'}
        close={() => closeRemoveStudPopup()}
        onClick={() => onClickRemove()}
      >
        <h2>Are you 100% sure it is the correct persom?</h2>
        <p>Are you sure want to remove this faculty?</p>
      </Popup>
      <Popup
        className={popUnArchiveStudClassName}
        buttonText="UnArchive"
        popupHead={'UnArchive "' + selectedTeacher?.name + '" to the list'}
        close={() => closeUnArchiveStudPopup()}
        onClick={() => onClickUnArchive()}
      >
        <h2>Are you 100% sure it is the correct person?</h2>
        <p>Are you sure want to unarchive this faculty?</p>
      </Popup>
    </div>
  )
}

export default TeacherList
