import {
  APP_LOGOUT,
  GET_FACULTIES_OF_CURRENT_USER_START,
  GET_FACULTIES_OF_CURRENT_USER_SUCCESS,
  GET_FACULTIES_OF_CURRENT_USER_FAILURE,
  POST_FACULTY_START,
  POST_FACULTY_SUCCESS,
  POST_FACULTY_FAILURE,
  GET_FACULTIES_START,
  GET_FACULTIES_SUCCESS,
  GET_FACULTY_SUCCESS,
  GET_FACULTIES_FAILURE,
  ARCHIVE_FACULTY_START,
  ARCHIVE_FACULTY_SUCCESS,
  ARCHIVE_FACULTY_FAILURE,
  UNARCHIVE_FACULTY_START,
  UNARCHIVE_FACULTY_SUCCESS,
  UNARCHIVE_FACULTY_FAILURE,
  GETALL_FACULTY_START,
  GETALL_FACULTY_SUCCESS,
  GETALL_FACULTY_FAILURE,
  POST_BULK_FACULTY_START,
  POST_BULK_FACULTY_SUCCESS,
  POST_BULK_FACULTY_FAILURE,
  PUT_FACULTY_NEW_USER_START,
  PUT_FACULTY_NEW_USER_SUCCESS,
  PUT_FACULTY_NEW_USER_FAIL
} from '../actions'

let initialState = {
  error: false,
  isFalcutiesLoading: false,
  myFaculties: [],
  faculties: [],
  isfaculty: [],
  facultyLists: [],
  isPostFalcutiesLoading: false,
  resetLoading: false
}

export const faculty = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_FACULTIES_OF_CURRENT_USER_START:
      return {
        ...state,
        error: false,
        isFalcutiesLoading: true,
      }
    case GET_FACULTIES_OF_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: false,
        myFaculties: action.my_faculties,
      }
    case GET_FACULTIES_OF_CURRENT_USER_FAILURE:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: true,
      }

    case POST_FACULTY_START:
      return {
        ...state,
        error: false,
        isPostFalcutiesLoading: true,
      }
    case POST_FACULTY_SUCCESS:
      return {
        ...state,
        isPostFalcutiesLoading: false,
        error: false,
      }
    case POST_FACULTY_FAILURE:
      return {
        ...state,
        isPostFalcutiesLoading: false,
        error: true,
      }
    case POST_BULK_FACULTY_START:
      return {
        ...state,
        isPostFalcutiesLoading: true,
        error: false,
      }
    case POST_BULK_FACULTY_SUCCESS:
      return {
        ...state,
        isPostFalcutiesLoading: false,
        error: false,
      }
    case POST_BULK_FACULTY_FAILURE:
      return {
        ...state,
        isPostFalcutiesLoading: false,
        error: true,
      }
    case GET_FACULTIES_START:
      return {
        ...state,
        error: false,
        isFalcutiesLoading: true,
      }
    case GET_FACULTIES_SUCCESS:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: false,
        faculties: action.faculties,
      }
    case GET_FACULTY_SUCCESS:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: false,
        faculties: action.faculty,
      }
    case GET_FACULTIES_FAILURE:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: true,
      }
    case ARCHIVE_FACULTY_START:
      return {
        ...state,
        error: false,
        isFalcutiesLoading: true,
      }
    case ARCHIVE_FACULTY_SUCCESS:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: false,
      }
    case ARCHIVE_FACULTY_FAILURE:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: true,
      }
    case UNARCHIVE_FACULTY_START:
      return {
        ...state,
        error: false,
        isFalcutiesLoading: true,
      }
    case UNARCHIVE_FACULTY_SUCCESS:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: false,
        isfaculty: action.isfaculty,
      }
    case UNARCHIVE_FACULTY_FAILURE:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: true,
      }
    case GETALL_FACULTY_START:
      return {
        ...state,
        error: false,
        isFalcutiesLoading: true,
      }
    case GETALL_FACULTY_SUCCESS:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: false,
        myFaculties: action.faculties,
      }
    case GETALL_FACULTY_FAILURE:
      return {
        ...state,
        isFalcutiesLoading: false,
        error: true,
      }
    case PUT_FACULTY_NEW_USER_START:
      return {
        ...state,
        resetLoading: true,
        error: false,
      }
    case PUT_FACULTY_NEW_USER_SUCCESS:
      return {
        ...state,
        resetLoading: false,
        error: false,
      }
    case PUT_FACULTY_NEW_USER_FAIL:
      return {
        ...state,
        resetLoading: false,
        error: true,
      }
    default:
      return state
  }
}

export const getIsFacultiesLoading = (state) => state.faculty.isFalcutiesLoading
export const getIsPostFalcutiesLoading = (state) =>
  state.faculty.isPostFalcutiesLoading
export const getFaculties = (state) => state.faculty.myFaculties
export const getIsError = (state) => state.faculty.error
export const getAllFaculties = (state) => state.faculty.faculties
export const getArchiveFalculty = (state) => state.faculty.myFaculties
export const getArchiveIsLoading = (state) => state.faculty.isFalcutiesLoading
export const getArchiveIsError = (state) => state.faculty.error
export const getUnArchiveFalculty = (state) => state.faculty.isfaculty
export const getUnArchiveIsLoading = (state) => state.faculty.isFalcutiesLoading
export const getUnArchiveIsError = (state) => state.faculty.error
export const fetchFalculty = (state) => state.faculty.myFaculties
export const fetchIsLoading = (state) => state.faculty.isFalcutiesLoading
export const fetchIsError = (state) => state.faculty.error
export const getResetLaoding = state => state.faculty.resetLoading
