import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

//helpers
import { usePrevious } from '../../helpers'

//assests
import Loader from '../../assets/molecules/loader'
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

//action
import { fetchBatches } from '../../../redux/action/batches'
import { startBulkAddStudent } from '../../../redux/action/students'
import { startBulkAddFaculty } from '../../../redux/action/faculty'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
    getBatches,
    getIsLoading
} from '../../../redux/reducer/batches'
import { getIsLoading as getStudentLoading, getIsError } from '../../../redux/reducer/students'
import {
    getIsPostFalcutiesLoading,
    getIsError as getFacultyError
} from '../../../redux/reducer/faculty'

const BulkUpload = () => {
    const dispatch = useDispatch()

    const jwt = useSelector(getJwt)
    const batches = useSelector(getBatches)
    const batchLoading = useSelector(getIsLoading)
    const studentLoading = useSelector(getStudentLoading)
    const studentLoadingPrev = usePrevious(studentLoading)
    const error = useSelector(getIsError)
    const facultyLoading = useSelector(getIsPostFalcutiesLoading)
    const facultyLoadingPrev = usePrevious(facultyLoading)
    const facultyError = useSelector(getFacultyError)

    const [selectedBatch, setSelectedBatch] = useState('')
    const [csv, setCsv] = useState('')
    const [uploadType, setUploadType] = useState('student')

    useEffect(() => {
        dispatch(fetchBatches({ jwt }))
    }, [])

    useEffect(() => {
        if (!studentLoading && studentLoadingPrev !== undefined) {
            setSelectedBatch('')
            setCsv('')
        }
    }, [studentLoading, studentLoadingPrev])

    useEffect(() => {
        if (!facultyLoading && facultyLoadingPrev !== undefined) {
            setSelectedBatch('')
            setCsv('')
        }
    }, [studentLoading, studentLoadingPrev])

    const onUploadTypeChange = e => {
        setUploadType(e.target.value)
        setSelectedBatch('')
        setCsv('')
    }

    const onBatchChange = e => {
        setSelectedBatch(e.target.value)
    }

    const onCSVChange = e => {
        setCsv(e.target.value)
    }

    const handleSubmit = () => {
        const payload = { content_url: csv }
        if (uploadType === 'student') {
            dispatch(startBulkAddStudent({ jwt, batchId: selectedBatch, payload }))
        } else {
            dispatch(startBulkAddFaculty({ jwt, payload }))
        }
    }

    return (
        <div className="body__container">
            {!studentLoading && studentLoadingPrev !== undefined && studentLoading !== studentLoadingPrev && !error &&
                <AlertSuccess>Student Uploaded successfully</AlertSuccess>
            }

            {!studentLoading && studentLoadingPrev !== undefined && studentLoading !== studentLoadingPrev && error &&
                <AlertError>Unable to Upload!</AlertError>
            }

            {!facultyLoading && facultyLoadingPrev !== undefined && facultyLoading !== facultyLoadingPrev && !facultyError &&
                <AlertSuccess>Faculty Uploaded successfully</AlertSuccess>
            }

            {!facultyLoading && facultyLoadingPrev !== undefined && facultyLoading !== facultyLoadingPrev && facultyError &&
                <AlertError>Unable to Upload!</AlertError>
            }
            
            {batchLoading
                ? <Loader />
                : <>
                    <section>
                        <h2>Bulk Upload Students or Faculties</h2>
                        <div className="row">
                            <div className="col-md-9 padding--0">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Bulk Upload Students or Faculties</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="clear"></div>
                        </div>
                    </section>
                    <Card columnNo="1" title="Student Bulk Upload" titleColor={2}>
                        <h5>Select Upload Type:</h5>
                        <select name="batch" onChange={(e) => onUploadTypeChange(e)} value={uploadType}>
                            <option value="">Select Type</option>
                            <option value="student">Student</option>
                            <option value="faculty">Faculty</option>
                        </select>

                        {uploadType === 'student' &&
                        <>
                            <h5>Select Batch:</h5>
                            <select name="batch" onChange={(e) => onBatchChange(e)} value={selectedBatch}>
                                <option value="">Select Batch</option>
                                {batches?.map((item, index) => {
                                    return (
                                        <option value={`${item._id}`} key={index}>
                                            {`${item.title}`}
                                        </option>
                                    )
                                })}
                            </select>
                        </>
                        }
                        <h5>Enter CSV:</h5>
                        <textarea
                            name="CSV File"
                            value={csv}
                            type="text"
                            placeholder="CSV File"
                            onChange={onCSVChange}
                        />
                        {(studentLoading || facultyLoading) ? (
                            <Loader />
                        ) : (
                            <Button
                                className="button--small"
                                color="blue"
                                text="Upload"
                                click={(e) => handleSubmit(e)}
                            />
                        )}
                    </Card>
                </>
            }
        </div>
    )
}

export default BulkUpload