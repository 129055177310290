import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

//helpers
import { usePrevious } from '../../helpers'

//assests
import Loader from '../../assets/molecules/loader'
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

//action
import { fetchBatches } from '../../../redux/action/batches'
import { startBulkAddStudent } from '../../../redux/action/students'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
    getBatches,
    getIsLoading
} from '../../../redux/reducer/batches'
import { getIsLoading as getStudentLoading, getIsError } from '../../../redux/reducer/students'

const BulkUplaodStudents = () => {
    const dispatch = useDispatch()

    const jwt = useSelector(getJwt)
    const batches = useSelector(getBatches)
    const batchLoading = useSelector(getIsLoading)
    const studentLoading = useSelector(getStudentLoading)
    const studentLoadingPrev = usePrevious(studentLoading)
    const error = useSelector(getIsError)

    const [selectedBatch, setSelectedBatch] = useState('')
    const [csv, setCsv] = useState('')

    useEffect(() => {
        dispatch(fetchBatches({ jwt }))
    }, [])

    useEffect(() => {
        if (!studentLoading && studentLoadingPrev !== undefined) {
            setSelectedBatch('')
            setCsv('')
        }
    }, [studentLoading, studentLoadingPrev])

    const onBatchChange = e => {
        setSelectedBatch(e.target.value)
    }

    const onCSVChange = e => {
        setCsv(e.target.value)
    }

    const handleSubmit = () => {
        const payload = { url: csv }
        dispatch(startBulkAddStudent({ jwt, batchId: selectedBatch, payload }))
    }

    return (
        <div className="body__container">
            {!studentLoading && studentLoadingPrev !== undefined && studentLoading !== studentLoadingPrev && !error &&
                <AlertSuccess>Student Uploaded successfully</AlertSuccess>
            }

            {!studentLoading && studentLoadingPrev !== undefined && studentLoading !== studentLoadingPrev && error &&
                <AlertError>Unable to Upload!</AlertError>
            }
            {batchLoading
                ? <Loader/>
                : <>
                    <section>
                        <h2>Bulk Upload Students</h2>
                        <div className="row">
                            <div className="col-md-9 padding--0">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Bulk Upload Students</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="clear"></div>
                        </div>


                    </section>
                    <Card columnNo="1" title="Student Bulk Upload" titleColor={2}>
                        <h5>Select Batch:</h5>
                        <select name="batch" onChange={(e) => onBatchChange(e)} value={selectedBatch}>
                            <option value="">Select Batch</option>
                            {batches?.map((item, index) => {
                                return (
                                    <option value={`${item._id}`} key={index}>
                                        {`${item.title}`}
                                    </option>
                                )
                            })}
                        </select>
                        <h5>Enter CSV:</h5>
                        <textarea
                            name="CSV File"
                            value={csv}
                            type="text"
                            placeholder="CSV File"
                            onChange={onCSVChange}
                        />
                        {studentLoading ? (
                            <Loader />
                        ) : (
                           <Button
                            className="button--small"
                            color="blue"
                            text="Upload"
                            click={(e) => handleSubmit(e)}
                        />
                        )}
                    </Card>
                </>
            }
        </div>
    )
}

export default BulkUplaodStudents