import {
  GET_INSTITUTION_START,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  POST_INSTITUTION_START,
  POST_INSTITUTION_SUCCESS,
  POST_INSTITUTION_FAILURE,
  GET_ALL_INSTITUTION_START,
  GET_ALL_INSTITUTION_SUCCESS,
  GET_ALL_INSTITUTION_FAILURE,
  DELETE_INSTITUTION_START,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAILURE,
  PATCH_INSTITUTION_START,
  PATCH_INSTITUTION_SUCCESS,
  PATCH_INSTITUTION_FAILURE
} from '../actions'
import { checkStatus } from '../helpers.js'

const getInstitutionStart = () => {
  return {
    type: GET_INSTITUTION_START,
  }
}

const getInstitutionSuccess = ({ institution }) => {
  return {
    type: GET_INSTITUTION_SUCCESS,
    institution,
  }
}

const getInstitutionFailed = () => {
  return {
    type: GET_INSTITUTION_FAILURE,
  }
}

const getInstitutionById = ({ institutionId, jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions/${institutionId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

//get institution by id
export const getInstitution = (data) => {
  return async (dispatch) => {
    dispatch(getInstitutionStart())
    try {
      const receivedData = await getInstitutionById(data)
      const response = await receivedData.json()
      dispatch(getInstitutionSuccess(response.data))
    } catch (err) {
      dispatch(getInstitutionFailed())
    }
  }
}

const postInstitutionStart = () => {
  return {
    type: POST_INSTITUTION_START,
  }
}

const postInstitutionSuccess = () => {
  return {
    type: POST_INSTITUTION_SUCCESS,
  }
}

const postInstitutionFailed = () => {
  return {
    type: POST_INSTITUTION_FAILURE,
  }
}

const postInstitutionApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

//post institution
export const postInstitution = (data) => {
  return async (dispatch) => {
    dispatch(postInstitutionStart())
    try {
      const receivedData = await postInstitutionApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postInstitutionSuccess(response))
    } catch (err) {
      dispatch(postInstitutionFailed())
    }
  }
}

//get all institutions
const getAllInstitutionStart = () => ({
  type: GET_ALL_INSTITUTION_START
})

const getAllInstitutionSuccess = ({ institutions }) => {
  return {
    type: GET_ALL_INSTITUTION_SUCCESS,
    institutions
  }
}

const getAllInstitutionFail = () => ({
  type: GET_ALL_INSTITUTION_FAILURE
})

const getAllInstitution = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const startGetAllInstitutions = (data) => {
  return async (dispatch) => {
    dispatch(getAllInstitutionStart())
    try {
      const receivedData = await getAllInstitution(data)
      const response = await receivedData.json()
      dispatch(getAllInstitutionSuccess(response.data))
    } catch (err) {
      dispatch(getAllInstitutionFail())
    }
  }
}

//patch institution
const patchInstitutionStart = () => ({
  type: PATCH_INSTITUTION_START
})

const patchInstitutionSuccess = () => ({
  type: PATCH_INSTITUTION_SUCCESS
})

const patchInstitutionFail = () => ({
  type: PATCH_INSTITUTION_FAILURE
})

const patchInstitution = ({ jwt, id, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions/${id}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const startPatchInstitution = (data) => {
  return async (dispatch) => {
    dispatch(patchInstitutionStart())
    try {
      const receivedData = await patchInstitution(data)
      const response = await receivedData.json()
      dispatch(patchInstitutionSuccess(response.data))
    } catch (err) {
      dispatch(patchInstitutionFail())
    }
  }
}

//archive institution
const archiveInstitutionStart = () => ({
  type: DELETE_INSTITUTION_START
})

const archiveInstitutionSuccess = () => ({
  type: DELETE_INSTITUTION_SUCCESS
})

const archiveInstitutionFail = () => ({
  type: DELETE_INSTITUTION_FAILURE
})

const archiveInstitution = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const startArchiveInstitution = (data) => {
  return async (dispatch) => {
    dispatch(archiveInstitutionStart())
    try {
      const receivedData = await archiveInstitution(data)
      const response = await receivedData.json()
      dispatch(archiveInstitutionSuccess(response.data))
    } catch (err) {
      dispatch(archiveInstitutionFail())
    }
  }
}