import {
  GET_INSTITUTION_START,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  POST_INSTITUTION_START,
  POST_INSTITUTION_SUCCESS,
  POST_INSTITUTION_FAILURE,
  GET_ALL_INSTITUTION_START,
  GET_ALL_INSTITUTION_SUCCESS,
  GET_ALL_INSTITUTION_FAILURE,
  DELETE_INSTITUTION_START,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAILURE,
  PATCH_INSTITUTION_START,
  PATCH_INSTITUTION_SUCCESS,
  PATCH_INSTITUTION_FAILURE
} from '../actions'

let initialState = {
  title: '',
  error: false,
  isInstitutionGetting: false,
  isInstitutionGot: false,
  isLoading: false,
  institutions: [],
  institutionLoading: false,
  patchLoading:false
}
export const institution = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTION_START:
      return {
        ...state,
        isInstitutionGetting: true,
        error: false,
      }
    case GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        isInstitutionGot: true,
        isInstitutionGetting: false,
        error: false,
        title: action.title,
      }
    case GET_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        isInstitutionGetting: false,
        isInstitutionGot: false,
      }
    case GET_ALL_INSTITUTION_START:
      return {
        ...state,
        institutionLoading: true,
        error: false,
      }
    case GET_ALL_INSTITUTION_SUCCESS:
      return {
        ...state,
        institutionLoading: false,
        error: false,
        institutions: action.institutions,
      }
    case GET_ALL_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        institutionLoading: false
      }
    case POST_INSTITUTION_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    case POST_INSTITUTION_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
      }
    case POST_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    case DELETE_INSTITUTION_START:
      return {
        ...state,
        patchLoading: true,
        error: false,
      }
    case DELETE_INSTITUTION_SUCCESS:
      return {
        ...state,
        error: false,
        patchLoading: false,
      }
    case DELETE_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        patchLoading: false,
      }
    case PATCH_INSTITUTION_START:
      return {
        ...state,
        patchLoading: true,
        error: false,
      }
    case PATCH_INSTITUTION_SUCCESS:
      return {
        ...state,
        error: false,
        patchLoading: false,
      }
    case PATCH_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        patchLoading: false,
      }
    default:
      return state
  }
}

export const getIsLoading = (state) => state.institution.isLoading
export const getIsError = (state) => state.institution.error

export const getInstitutionName = (state) => state.institution.title
export const getAllInstitution = state => state.institution.institutions
export const getInstitutionLoading = state => state.institution.institutionLoading
export const getPatchInstitutionLoading = state => state.institution.patchLoading
