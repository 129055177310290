import { UPLOAD_FILE_START, UPLOAD_FILE, UPLOAD_FILE_FAIL } from '../actions.js'

import { checkStatus } from '../helpers.js'

//get profile info
const uploadFileStart = () => {
  return {
    type: UPLOAD_FILE_START,
  }
}

const uplaodFile = (data) => {
  return {
    type: UPLOAD_FILE,
    data,
  }
}

const uploadFileFailure = () => {
  return {
    type: UPLOAD_FILE_FAIL,
  }
}
export const fileUpload = (body) => {
  const url = `https://api2.sealtabs.com/api/s3/multiple/public`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/*',
    },
    body: body,
  })
}

export const startFileUpload = (data) => {
  return async (dispatch) => {
    dispatch(uploadFileStart())
    try {
      const receivedData = await fileUpload(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(uplaodFile(response?.data))
    } catch (err) {
      dispatch(uploadFileFailure())
    }
  }
}
