import React from 'react'
import New from '../../../assets/images/old/new.png'
import Completed from '../../../assets/images/old/success.png'

const Card = (props) => {
  let status = ''
  if (props.icon) {
    status = Completed
  } else if (props.icon === false) {
    status = New
  } else {
    status = null
  }
  return (
    <div onClick={props.onClick} className={'card--click ' + props.className}>
      <div className={'col col--' + props.columnNo}>
        <div className="card">
          <h3 className={'background--color' + props.titleColor} style={{padding: 8}}>
            {props.title}
            <span className="icon--status">
              <img src={status} alt={status} />
            </span>
          </h3>
          {props.children}
          <div className="clear"></div>
        </div>
      </div>
    </div>
  )
}

export default Card
