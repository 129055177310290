import React from 'react'
import { useHistory } from 'react-router-dom'

//assets
import Col from '../../assets/molecules/column'
import Button from '../../assets/molecules/button'

import Institutions from '../../owner/institutions/view'

const Owner = () => {
  const history = useHistory()

  const onClickCreateInstitution = () => {
    history.push('/create-institution')
  }

  return (
    <div className="tabs__container">
      <section>
        <Col className="float-right" no="25">
          <Button
            className="float-right margin0"
            click={() => onClickCreateInstitution()}
            color="blue"
            text="Create Institution"
          />
        </Col>
      </section>
      <Institutions />
    </div>
  )
}

export default Owner
