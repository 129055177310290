import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//actions
import { 
  startGetAllInstitutions,
  startArchiveInstitution,
  startPatchInstitution
} from '../../../redux/action/institution'
import { startFileUpload } from '../../../redux/action/upload'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  getInstitutionLoading,
  getAllInstitution,
  getPatchInstitutionLoading,
  getIsError
} from '../../../redux/reducer/institution'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../../redux/reducer/upload'

//helpers
import { usePrevious } from '../../helpers'

//components
import Loader from '../../assets/molecules/loader'
import Popup from '../../assets/layout/popupConfirm'
import PopupForm from '../../assets/layout/popupForm'

const Institutions = () => {
  const dispatch = useDispatch()

  const jwt = useSelector(getJwt)
  const institutions = useSelector(getAllInstitution)
  const institutionLoading = useSelector(getInstitutionLoading)
  const institutionLoadingPrev = usePrevious(institutionLoading)
  const patchLoading = useSelector(getPatchInstitutionLoading)
  const patchLoadingPrev = usePrevious(patchLoading)
  const error = useSelector(getIsError)
  const uploadedFile = useSelector(getFile)
  const uploadLoading = useSelector(getIsLoadingUploadFile)
  const uploadLoadingPrev = usePrevious(uploadLoading)
  const fileUploadError = useSelector(getFileUploadError)

  const [file, setFile] = useState()
  const [selectedInstitution, setSelectedInstitution] = useState({})
  const [showArchiveClassname, setShowArchiveClassname] = useState('hidePop')
  const [showEditClassname, setShowEditClassname] = useState('hidePop')

  useEffect(() => {
    dispatch(startGetAllInstitutions({ jwt }))
  }, [])

  useEffect(() => {
    if (!patchLoading && patchLoadingPrev !== undefined && patchLoading !== patchLoadingPrev && !error) {
      dispatch(startGetAllInstitutions({ jwt }))
      setSelectedInstitution({})
      setShowArchiveClassname('hidePop')
    }
  }, [patchLoading, patchLoadingPrev, error])

  const openArchivePopup = (e, item) => {
    setSelectedInstitution(item)
    setShowArchiveClassname('showPop')
  }

  const openEditPopup = (e, item) => {
    setSelectedInstitution(item)
    setShowEditClassname('showPop')
  }

  const closePopup = () => {
    setSelectedInstitution({})
    setShowArchiveClassname('hidePop')
    setShowEditClassname('hidePop')
  }

  const onChange = e => {
    setSelectedInstitution({...selectedInstitution, [e.target.name]: e.target.value})
  }

  const onFileUpload = e => {
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    dispatch(startFileUpload(data))
  }

  const handleEditSubmit = e =>  {
    const payload = {
      "title": selectedInstitution?.title,
      // "nav_logo": "string",
      "sealtabs_institution_id": selectedInstitution?.sealtabs_institution_id,
      "academic_year": selectedInstitution?.academic_year
    }
    dispatch(startPatchInstitution({ jwt, id: selectedInstitution, payload }))
  }

  const handleArchiveSubmit = () => {
    dispatch(startArchiveInstitution({ jwt, id: selectedInstitution?._id}))
  }

  return (
    institutionLoading ? <Loader /> :
    <div>
        <h2>Active Institutions</h2>
        <div className="table-responsive">
          <table className={'table'}>
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Name</th>
                <th>Academic Year</th>
                <th>Edit</th>
                <th>Archive</th>
              </tr>
            </thead>
            <tbody>
              {institutions && institutions?.length > 0 ?
                institutions?.filter(item => item?.visibility)?.map((item, index) => {
                  return (
                    <tr key={item?._id}>
                      <td>{index + 1}</td>
                      <td className="pointer">
                        {item?.title}
                      </td>
                      <td>
                        {item?.academic_year}
                      </td>
                      <td>
                        <button onClick={(e) => openEditPopup(e, item)}>
                          Edit
                        </button>
                      </td>
                      <td>
                        <button onClick={(e) => openArchivePopup(e, item)}>
                          Archive
                        </button>
                      </td>
                    </tr>
                  )
                }) : null}
            </tbody>
          </table>
        </div>

        <PopupForm
          className={showEditClassname}
          close={() => {
            closePopup()
          }}
          onClickButton={handleEditSubmit}
          buttonText={'EDIT'}
          popupHead={'Edit Institution'}
        >
          <form>
            <ol>
              <li>
                <h2>Institution Name</h2>
                <div className="form-group sessionTitle">
                  <input
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    value={selectedInstitution?.title}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                </div>
              </li>
              <li>
                <h2>Academic Year</h2>
                <div className="form-group sessionClass">
                  <input
                    type="text"
                    name="academic_year"
                    value={selectedInstitution?.academic_year}
                    onChange={onChange}
                    placeholder="Academic Year"
                  />
                </div>
              </li>
              <li>
                <h2>SEALTABS Institution ID</h2>
                <div className="form-group homeCard__subject">
                  <input
                    type="text"
                    name="sealtabs_institution_id"
                    value={selectedInstitution?.sealtabs_institution_id}
                    onChange={onChange}
                    placeholder="SEALTABS Institution ID"
                  />
                </div>
              </li>
              {/* <li>
                <h2>Logo</h2>
                <div className="form-group homeCard__subject">
                  <input
                    type="file"
                    name={'nav_logo'}
                    // ref={notesRef.current[0]}
                    value={file}
                    onChange={onFileUpload}
                    placeholder="Logo"
                  />
                </div>
              </li> */}
            </ol>
          </form>
        </PopupForm>

        <Popup
          className={showArchiveClassname}
          buttonText="Archive"
          popupHead={'Archive Institution'}
          close={() => closePopup()}
          onClick={() => handleArchiveSubmit()}
        >
          <h2>Are you wan to archive {selectedInstitution?.title}?</h2>
        </Popup>
    </div>
  )
}

export default Institutions
